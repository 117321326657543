@import "~antd/dist/antd.css";

$dark-background: #001529;
$gold-color: #FFE9C1;
$background: $gold-color;
// $background: #F0F2F5

@import '~react-flags-select/scss/react-flags-select.scss';

.flag-select {
  background-color: white !important;
  min-width: 174px; }

.flag-select__option__label {
  color: black; }

body {
  font-family: Prompt, sans-serif; }

.starter-card {
  background-color: #001529;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 11px;
  font-size: 130%;
  color: white;
  .link-text {
    color: #F4B74B; } }

.logo-img {
  max-width: 160px; }
.content-wrapper {
  margin: 24px 16px;
  padding: 24px;
  min-height: 280px; }
.content-wrapper-margin-less {
  margin: 0; }

.menu-float-right {
  float: right; }
.is-fullheight {
  min-height: 100vh; }
.is-fullheight-with-navbar {
  min-height: calc( 100vh - 64px) !important; }
.height-full {
  height: 100% !important; }
.theme-background {
  background-image: url('../Common/Images/laptop-background.png') !important;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center; }

.is-padded-top {
  padding-top: 60px !important; }

.is-bold {
  font-weight: 700 !important; }
.is-uppercase {
  text-transform: uppercase; }
.no-letter-spacing {
  letter-spacing: 0px !important; }

.has-text-centered {
  text-align: center !important; }

/// IFrame

.iframe-container {
  position: relative;
  padding-bottom: 56.25%;
  margin: 1rem;
  height: 0; }
.iframe-container iframe {
  position: absolute;
  top : 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px black solid; }

.dark-button {
  background-color: $dark-background; }

.is-marginless {
  margin: 0; }
.align-center {
  text-align: center; }

.black-divider {
  border-top-color: #000;
  margin: 20px 0 0 0; }

/// Content Wrapper
.site-layout {
  .site-layout-background {}
 }  //  background: #fff
.content-wrapper {
  margin: 24px 16px;
  padding: 24px;
  min-height: 280px; }
.content-wrapper-margin-less {
  margin: 0; }

/// FAQ

[data-theme='compact'] .site-collapse-custom-collapse .site-collapse-custom-panel,
.site-collapse-custom-collapse .site-collapse-custom-panel {}
//  background: $dark-background
//border-radius: 2px
//margin-bottom: 24px
//border: 0px
//overflow: hidden

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: white;
  font-weight: normal; }

.ant-layout-sider {
  background: white; }
